import { OperationOptions } from "@azure/core-client";
import { createErrorToast } from "@components/ToastContainer";
import { defer } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ApiCallError } from "../utils/swaggerClientUtils";

const queryApiInternal = <T>(
	call: (options: OperationOptions) => Promise<T>,
	deps: any[],
	throwError: boolean = false) => {

	const [state, setState] = useState<T | null>();

	const updateState = async () => {
		const hasUnsetDependency = deps.find(x => !x);
		if (hasUnsetDependency) {
			setState(undefined);
			return;
		}

		try {
			let hasOnResponseFired = false;
			await call({
				onResponse: response => {
					hasOnResponseFired = true;

					if (response.status > 399) {
						new ApiCallError(response.status, response.headers, response.bodyAsText)
					}
					else {
						setState(response.parsedBody);
					}
				}
			}).then(response => {
				defer(() => {
					if (!hasOnResponseFired) {
						console.warn("A request was performed but onResponse was never triggered. Perhaps you forgot to pass options in to the API call of queryApi?");
					}
					setState(response);
				});
			});
		} catch (e: unknown) {
			var error = e as Error;
			if (error instanceof ApiCallError && error.statusCode == 404) {
				setState(null)
			}
			else {
				createErrorToast(error.message)
			}
			if (throwError) {
				throw e;
			}
		};
	};

	const refresh = useCallback(
		() => updateState(),
		deps);

	useEffect(
		() => {
			updateState();
		},
		deps);

	return [state, refresh] as [typeof state, typeof refresh];
};

export const queryApi = <T>(
	call: (options: OperationOptions) => Promise<T>,
	deps: any[],
	throwError: boolean = false): [T | null | undefined, () => Promise<unknown>] => {
	const [result, setResult] = queryApiInternal(call, deps, throwError)

	return [result, setResult]
}
