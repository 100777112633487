import { pickLargestImage } from "@utils/images";
import { generateCanonicalItemRouteUrlFromItem, ItemRoute } from "@utils/items/UrlFilterParser";
import { Helmet } from "react-helmet";
import type { WebItemResponse } from "src/types";

export function ItemMicrodataComponent(props: {
    item?: WebItemResponse,
    route: ItemRoute
}) {
    const item = props.item;
    if(!item)
        return null;

    return <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(getMicrodataObject(item))}
        </script>
    </Helmet>
}

export function ItemsMicrodataComponent(props: {
    items: WebItemResponse[]
}) {
    const items = props.items;
    if(!items || items.length === 0)
        return null;

    return <Helmet>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@graph": items.map(getMicrodataObject)
            })}
        </script>
    </Helmet>
}

function getMicrodataObject(
    item: WebItemResponse
): any {
    if(!item)
        return null;

    let itemCondition: string|null = null;
    switch(item.condition) {
        case 'new':
        case 'brandNew': {
            itemCondition = "New";
            break;
        }

        case 'broken': {
            itemCondition = "Damaged";
            break;
        }

        case 'used': {
            itemCondition = "Used";
            break;
        }
    }

    const address = item?.user?.address;
    const location = address?.location;

    const now = new Date();
    return {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": item.description,
        "image": pickLargestImage(item.images)?.url,
        "description": item.extendedDescription || item.description,
        "brand": {
            "@type": "Thing",
            "name": item.brandOrTitle
        },
        "url": generateCanonicalItemRouteUrlFromItem(window.location.href, item),
        "sku": item.id,
        "productID": item.id,
        "purchaseDate": item.soldOutDateUtc?.toISOString(),
        "offers": {
            "@type": "Offer",
            "priceCurrency": item.currency?.toUpperCase(),
            "priceValidUntil": new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()).toISOString(),
            "price": item.priceInHundreds / 100,
            "itemCondition": itemCondition && `http://schema.org/${itemCondition}Condition`,
            "availability": "OnlineOnly",
            "availableAtOrFrom": {
                "@type": "Place",
                "address": {
                    "@type": "GeoCoordinates",
                    "latitude": location?.lat,
                    "longitude": location?.lon
                }
            }
        }
    };
}
