import { webClient } from "@api/web/WebClient";
import Logo from "@components/Logo";
import Seo from "@components/Seo";
import { withWebLayout } from "@components/web/Decorators";
import DownloadAppButton from "@components/web/DownloadAppButton";
import ItemCard from "@components/web/items/ItemCard";
import ItemCardGrid from "@components/web/items/ItemCardGrid";
import { ItemMicrodataComponent } from "@components/web/items/ItemMicrodataComponent";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { queryApi } from "@hooks/api";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useLocation } from "@reach/router";
import { WebItemsGetResponse } from "@reshopper/web-client";
import { extractItemRouteInformation, ItemRoute } from "@utils/items/UrlFilterParser";
import { navigateTo404 } from "@utils/navigation";
import { combineClassNames } from "@utils/strings";
import { useState } from "react";
import styles from "./details.module.scss";

/**
 * @description This page is routed to directly by the Cloudflare Worker.
 */
export default withWebLayout((props: {
    route?: ItemRoute
}) => {
    const location = useLocation();
    const route = props.route || extractItemRouteInformation(location?.href);
    const id = route?.id;

    const [hasMoreLikeThis, setHasMoreLikeThis] = useState<boolean | null>(null);
    const [itemResponse] = queryApi(
        async (options) => await webClient().webItemsGet(id!, options),
        [id]);

    if (!location)
        return null;

    if (!route)
        return null;

    if (itemResponse === null)
        return navigateTo404();

    return <FrontPageLayout includeFooter={shouldIncludeFooter(hasMoreLikeThis)}>
        <div className={styles.root}>
            <Logo variant="text-white" href="/" />

            {!itemResponse ?
                <CircularProgress style={{
                    color: 'white'
                }} /> :
                <ItemDetailsContent
                    route={route}
                    item={itemResponse}
                    onMoreLikeThisLoaded={setHasMoreLikeThis}
                />}
        </div>
    </FrontPageLayout>;
});

function ItemDetailsContent(props: {
    item: WebItemsGetResponse,
    route: ItemRoute,
    onMoreLikeThisLoaded: (hasMoreLikeThis: boolean) => void
}) {
    const [hasMoreLikeThis, setHasMoreLikeThis] = useState<boolean | null>(null);
    const [isItemLoaded, setIsItemLoaded] = useState<boolean>(false);

    const itemResponse = props.item;

    let seoTitle = `Køb ${itemResponse?.brandOrTitle} ${itemResponse.description}`;
    if (itemResponse.size)
        seoTitle += ` i størrelse ${itemResponse.size}`;

    seoTitle += ` af ${itemResponse?.user?.firstName} på Reshopper`;

    return <>
        <ItemMicrodataComponent route={props.route} item={itemResponse} />
        <Seo title={seoTitle} description={itemResponse.extendedDescription} />

        <Typography variant="h1" className={combineClassNames(styles.title, "bodyText")}>{seoTitle}</Typography>
        <main>
            <ItemCard
                isDetailed={true}
                item={itemResponse}
                onLoaded={() => setIsItemLoaded(true)}
                width={350}
                className={styles.itemCard}
                country={props.route.country}
                showImageCarousel={true}
            />
            <Box textAlign={"center"}>
                <DownloadAppButton text="Hent Reshopper App her" />
            </Box>
        </main>

        {isItemLoaded && <>
            {hasMoreLikeThis === true && <h2>Lignende varer</h2>}
            <ItemCardGrid
                onLoaded={ev => {
                    setHasMoreLikeThis(ev.hasItems);
                    props.onMoreLikeThisLoaded(ev.hasItems);
                }}
                maximumItemCount={30}
                country={props.route.country}
                fetchItems={async options =>
                    itemResponse && await webClient().webQueriesItemsMoreLikePost({
                        body: {
                            country: itemResponse.user?.address?.country,
                            includedItemIds: [itemResponse.id],
                            segment: itemResponse.segment,
                            sortBy: "asIs",
                            exactSize: itemResponse.size,
                            ...options
                        }
                    })} />
        </>}
    </>
}

function shouldIncludeFooter(hasMoreLikeThis: boolean | null): boolean {
    //we wait until all data has been fetched before rendering the footer, to reduce CLS (Cumulative Layout Shift).
    return hasMoreLikeThis !== null;
}

