/* eslint-disable react-hooks/exhaustive-deps */

import ItemCard from "@components/web/items/ItemCard";
import { ItemsMicrodataComponent } from "@components/web/items/ItemMicrodataComponent";
import { useBottomScrollListener } from "@hooks/scrolling";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Countries, ElasticFacet, WebFacetOptionsByTypeResponse } from "@reshopper/web-client";
import { take } from "lodash";
import { useMemo, useRef, useState } from "react";
import type { WebItemResponse } from "src/types";
import { isDevelopment } from "../../../utils/swaggerClientUtils";
import styles from "./ItemCardGrid.module.scss";

type QueryResponse = {
  items: WebItemResponse[];
  facets?: WebFacetOptionsByTypeResponse[];
  totalHits: number;
}

export default function ItemCardGrid(props: {
    fetchItems: (options: {
        offset: number, 
        pageSize: number, 
        facets: ElasticFacet[]|undefined,
		  omitPointInTime: boolean
    }) => Promise<QueryResponse>,
    className?: string,
    facets?: ElasticFacet[],
    maximumItemCount?: number,
    country: Countries,
    onLoaded?: (event: {
        hasItems: boolean, 
        totalCount: number,
        facets?: WebFacetOptionsByTypeResponse[]
    }) => void
}) {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

    const pageSize = Math.min(
        props.maximumItemCount || 0, 
        isDevelopment() ? 3 : 9);

    const allItemsReference = useRef<WebItemResponse[]>([]);
    const [allItems, setAllItems] = useState<WebItemResponse[]>([]);
    const isLoaded = useRef(false);

    const [totalSize, setTotalSize] = useState(0);
    const hasReachedEnd = useMemo(
        () => 
            allItems.length >= totalSize ||
            (props.maximumItemCount && allItems.length >= props.maximumItemCount),
        [allItems, totalSize]);

    const loadNextChunk = async () => {
        if(props.maximumItemCount && allItemsReference.current.length > props.maximumItemCount)
            return 0;

        const facets = allItemsReference.current.length === 0 ?
            props.facets :
            undefined;

        const itemsResponse = await props.fetchItems({
            offset: allItemsReference.current.length, 
            pageSize,
            facets: facets,
				omitPointInTime: false
        });

        const amountToTake = props.maximumItemCount ?
            props.maximumItemCount - allItemsReference.current.length :
            30;
        allItemsReference.current = [
            ...allItemsReference.current, 
            ...take(
                itemsResponse.items, 
                amountToTake)
        ];

        setTotalSize(itemsResponse.totalHits);
        setAllItems(allItemsReference.current);

        if(!isLoaded.current) {
            isLoaded.current = true;

            props.onLoaded && props.onLoaded({
                hasItems: allItemsReference.current.length > 0,
                facets: itemsResponse.facets,
                totalCount: itemsResponse.totalHits
            });
        }

        return itemsResponse.items?.length || 0;
    };

    const gridRef = useRef<HTMLDivElement|null>(null);
    useBottomScrollListener(
        gridRef, 
        100,
        loadNextChunk);

    return <>
        <ItemsMicrodataComponent items={allItems} />
        <div className={styles.items + " " + props.className + " " + (!isLarge && styles.itemsSmall)} ref={gridRef}>
            {allItems ?
                allItems.map(item => {
                    return <div
                        className={styles.itemCardWrapper}
                    >
                        <ItemCard 
                            item={item}
                            isDetailed={false}
                            width={isLarge ?
                                240 :
                                110}
                            className={styles.itemCard}
                            country={props.country}
                        />
                    </div>}) :
                <CircularProgress />}
        </div>
        {!hasReachedEnd && <div style={{
            height: '200vh'
        }} />}
    </>
}