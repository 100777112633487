export function toPascalCase(input: string|undefined|null) {
	if (!input)
		 return "";

	if (input.length <= 1)
		 return input.toUpperCase();

	return input[0].toUpperCase() + input.substr(1);
}

export function toCamelCase(input: string|undefined|null) {
	if (!input)
		 return "";

	if (input.length <= 1)
		 return input.toLowerCase();

	return input[0].toLowerCase() + input.substr(1);
}

export function combineClassNames(...classNames: (string|undefined|null|false)[]) {
	return classNames.filter(x => x).join(" ");
}