import { delay } from "@utils/miscellaneous";
import { useLayoutEffect, useRef } from "react";

export const useBottomScrollListener = (
    ref: React.MutableRefObject<HTMLElement|null>,
    slack: number,
    fetchItemsCallback: () => Promise<number>|number
) => {
    const isLoading = useRef(false);
    const lastScrollTop = useRef(-1);

    const isScrolledToBottom = () => {
        const target = ref.current;
        if(!target)
            return false;

        const targetRectangle = target.getBoundingClientRect();

        return targetRectangle.bottom - slack < window.innerHeight;
    }

    const onScroll = async () => {
        if(isLoading.current)
            return;

        if(lastScrollTop.current === document.documentElement.scrollTop)
            return;

        lastScrollTop.current = document.documentElement.scrollTop;
        isLoading.current = true;

        try {
            while(isScrolledToBottom()) {
                const count = await Promise.resolve(fetchItemsCallback());
                if(count === 0)
                    break;

                await delay(100);
            }
        } finally {
            isLoading.current = false;
        }
    };

    useLayoutEffect(
        () => {
            window.addEventListener('resize', onScroll);
            window.addEventListener('scroll', onScroll);

            document.body.addEventListener('resize', onScroll);
            document.body.addEventListener('scroll', onScroll);

            onScroll();

            return () => {
                window.removeEventListener('resize', onScroll);
                window.removeEventListener('scroll', onScroll);

                document.body.removeEventListener('resize', onScroll);
                document.body.removeEventListener('scroll', onScroll);
            };
        },
        [ref, onScroll]);
}